import React, { useEffect, useState } from "react";
import "./App.css";

const workerURL = "https://weather_app.jwswj.workers.dev";
// const workerURL = "http://localhost:8787/";

function WeatherTable({ data, title }) {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan="4">{title}</th>
        </tr>
        <tr>
          <th>Date/Time</th>
          <th>Temperature</th>
          <th>Weather</th>
          <th>Humidity</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{new Date(item.dt * 1000).toLocaleString()}</td>
            <td>{item.temp.toFixed(2)}°C</td>
            <td>{item.weather.description}</td>
            <td>{item.humidity}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function App() {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    async function fetchWeatherData() {
      try {
        const response = await fetch(workerURL);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        setWeatherData(data.weatherData);
        console.log(data.weatherData);
      } catch (error) {
        console.error("Failed to fetch weather data:", error.message);
      }
    }

    fetchWeatherData();
  }, []);

  if (!weatherData) {
    return <div>Loading...</div>;
  }

  const { daily, hourly } = weatherData;

  return (
    <div className="App">
      <header>
        <h1>Weather Forecast for Melbourne, Australia</h1>
      </header>
      <div className="container">
        <div className="column">
          <WeatherTable data={hourly} title="Hourly Forecast for Today" />
          <WeatherTable data={daily} title="7-Day Forecast" />
        </div>

        <div className="column">
          <h2>Melbourne 128km Radar</h2>
          <iframe src="https://bom_rewrite_worker.jwswj.workers.dev/products/IDR023.loop.shtml#skip" title="radar"></iframe>
        </div>
      </div>
    </div>
  );
}

export default App;
